body {
    font-family: "Montserrat";
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: url("../public/BackgroundB.png") center center no-repeat fixed;
    background-position: center 45%;
    background-size: cover;
}

.container {
    margin-top: 140px;
    height: 85vh;
    display: flex;
    justify-content: center;
}

.home {
    background: url("../public/BackgroundA\ .png") center center no-repeat fixed;
    background-repeat: no-repeat;
    background-position: center 45%;
    background-size: cover;
}

a {
    color: white;
    text-decoration: none;
}

.active {
    font-weight: bold;
}

ul {
    list-style: none;
}

form {
    display: flex;
    flex-direction: column;
}

label {
    display: flex;
    flex-direction: column;
    text-align: left;
}

label span {
    font-weight: bold;
    margin-bottom: 24px;
}

input {
    background: rgba(217, 217, 217, 0.1);
    backdrop-filter: blur(10px);
    border-width: 1px;
    border-color: rgba(217, 217, 217, 0.308);
    border-radius: 8px;
    padding: 10px 8px;
    margin-bottom: 12px;
    color: #fff;
}

input::placeholder {
    color: #ffffffb0;
}

.btn,
button,
input[type="submit"] {
    background-color: rgb(15, 155, 31);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 8px;
    font-weight: bold;
    cursor: pointer;
    opacity: .9;
}

input:disabled {
    cursor: not-allowed;
    background: rgba(217, 217, 217, 0.1);
}

button:hover,
input[type="submit"]:hover {
    opacity: 1;
}

cancel-btn {
    background-color: #ccc;
}

.hide {
    display: none;
}