.message {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .9em;
    border-radius: 8px;
}

.message.error {
    color: #721c24;
    background-color: #f8d7da;
}

.message.success {
    color: rgb(15, 155, 31);
    background-color: rgb(186, 233, 191);
}