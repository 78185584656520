#profile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-image {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin-bottom: 12px;
}

.profile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.profile-description {
    color: white;
}

.line {
    width: 90vw;
}

h3 {
    text-align: center;
    color: #fff;
}

.new-photo {
    border-bottom: 1px solid #fff;
    width: 90vw;
}

.edit-photo {
    border-bottom: 1px solid #fff;
    width: 90vw;
}

.edit-photo img {
    width: 90vw;
}

.photos-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.photo {
    display: flex;
    justify-content: center;
    width: 100vw;
    margin-bottom: 36px;
    padding-bottom: 36px;
    border-bottom: 1px solid #fff;
}

.photo img {
    width: 90vw;
    height: auto;
}

.actions {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 12px;
    height: 100%;
    color: #fff;
}

.actions svg {
    cursor: pointer;
    margin-bottom: 12px;

}

.cancel-btn {
    background-color: #fff;
    color: rgb(15, 155, 31);
    border-width: 1px;
    border-color: rgb(15, 155, 31);
    opacity: .8;
}

.user-photos h2 {
    text-align: center;
    color: #fff;
}

.photos-container p {
    color: #fff;
}