#footer {
    position: fixed;
    bottom: 0;
    height: 24px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: -1;
}