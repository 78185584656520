.comments {
    padding: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 12px;
    background: rgba(217, 217, 217, 0.1);
    backdrop-filter: blur(10px);
}

.author img {
    width: 24px;
}

.comment {
    margin-bottom: 24px;
}

.comment p {
    color: white;
}