#nav {
    position: fixed;
    top: 0;
    padding-top: 48px;
    padding-bottom: 48px;
    width: 100vw;
    height: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
}

#logo {
    margin-left: 24px;
}

#search-form {
    position: relative;
}

#search-form input {
    padding-left: 32px;
    width: 100%;
    height: 20px;
}

#search-form svg {
    z-index: 3;
    position: absolute;
    top: 12px;
    left: 8px;
    color: #fff;
    cursor: pointer;
}

#nav-links {
    margin-right: 24px;
    display: flex;
    align-items: center;
}

#nav-links li {
    margin-right: 24px;
}

#nav-links span {
    cursor: pointer;
    color: white;
}

.header-active {
    background: rgba(217, 217, 217, 0.1);
    backdrop-filter: blur(10px);
}